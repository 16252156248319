import * as styles from './Output.module.scss'

import React, { useState, useCallback, useMemo } from 'react'

import { copyToClipboard } from 'utils/copyToClipboard'
import { downloadCSV } from 'utils/downloadCSV'
import { downloadText } from 'utils/downloadText'
import { usePersistentState } from 'utils/usePersistentState'

import * as controls from 'components/controls'

import { CopyButton } from '../components/CopyButton'
import { Modal } from '../components/Modal'

import {
  Attribute,
  OutputAttribute,
  OutputMode,
  RowWithData,
  SortMethod,
} from '../data/types'
import { formatCSV } from '../data/formatCSV'
import { formatText } from '../data/formatText'
import { sortRows } from '../data/sortRows'
import { defaultFormatTemplate } from '../data/formatText/formatExamples'
import { formatYAML } from '../data/formatYAML'
import { ImageArchiveDownloadModal } from '../image-archive-downloader/ImageArchiveDownloadModal'
import { ImageCompositeDownloaderModal } from '../image-composite-downloader/ImageCompositeDownloaderModal'

import { CardsTable } from './CardsTable'
import { AttributeSelection } from './attribute-selection/AttributeSelection'
import { TextConfiguration } from './TextConfiguration'
import { TextOutput } from './TextOutput'
import { CSVPreview } from './CSVPreview'
import { Images } from './Images'
import { YAMLPreview } from './YAMLPreview'
import { SortOptions } from './SortOptions'

interface Props {
  customAttributes: Attribute[]
  outputAttributes: OutputAttribute[]
  setOutputAttributes(value: OutputAttribute[]): void
  fetchedData: RowWithData[]
}

export const Output: React.FC<Props> = (props) => {
  const {
    customAttributes,
    outputAttributes,
    setOutputAttributes,
    fetchedData,
  } = props

  const [outputMode, setOutputMode] = usePersistentState<OutputMode>(
    'list-formatter-output-mode',
    'table',
  )

  const [textRowTemplate, setTextRowTemplate] = usePersistentState(
    'list-formatter-text-row-template',
    defaultFormatTemplate,
  )

  const [includeHeadings, setIncludeHeadings] = usePersistentState(
    'list-formatter-include-headings',
    true,
  )

  const [sortMethod, setSortMethod] = useState<SortMethod | null>(null)

  const [showSortOptions, setShowSortOptions] = useState(false)
  const [showColumnSelection, setShowColumnSelection] = useState(false)
  const [showTextConfiguration, setShowTextConfiguration] = useState(false)
  const [shortArchiveDownloader, setShowArchiveDownloader] = useState(false)
  const [showCompositeDownloader, setShowCompositeDownloader] = useState(false)

  const notFoundCount = useMemo(() => {
    return fetchedData.filter((row) => row.notFound).length
  }, [fetchedData])

  const sortedData = useMemo(() => {
    return sortMethod != null ? sortRows(fetchedData, sortMethod) : fetchedData
  }, [fetchedData, sortMethod])

  const hasOutput = useMemo(() => {
    return fetchedData.length > 0
  }, [fetchedData])

  // Export

  const copyOutputCSV = useCallback(() => {
    copyToClipboard(formatCSV(sortedData, outputAttributes, includeHeadings))
  }, [sortedData, outputAttributes, includeHeadings])

  const downloadOutputCSV = useCallback(() => {
    downloadCSV(
      formatCSV(sortedData, outputAttributes, includeHeadings),
      'card-list',
    )
  }, [sortedData, outputAttributes, includeHeadings])

  const copyOutputText = useCallback(() => {
    copyToClipboard(formatText(sortedData, textRowTemplate))
  }, [sortedData, textRowTemplate])

  const downloadOutputText = useCallback(() => {
    downloadText(formatText(sortedData, textRowTemplate), 'card-list')
  }, [sortedData, textRowTemplate])

  const copyOutputYAML = useCallback(() => {
    copyToClipboard(formatYAML(sortedData, outputAttributes))
  }, [sortedData, outputAttributes])

  const downloadOutputYAML = useCallback(() => {
    downloadText(formatYAML(sortedData, outputAttributes), 'card-list')
  }, [sortedData, outputAttributes])

  return (
    <div className={styles.container}>
      <div className={styles.outputOptions}>
        <controls.ControlGroup>
          <controls.SegmentedControl
            segments={{
              text: 'Text',
              table: 'Table',
              csv: 'CSV',
              yaml: 'YAML',
              images: 'Images',
            }}
            value={outputMode}
            onChange={(event) => {
              setOutputMode(event.currentTarget.value as any)
            }}
          />

          {sortedData.length > 0 && <div>{sortedData.length} Cards</div>}
          {notFoundCount > 0 && <div>{notFoundCount} Not Found</div>}
        </controls.ControlGroup>

        {(outputMode === 'table' || outputMode === 'csv') && (
          <controls.ControlGroup>
            <controls.SmallButton
              onClick={() => setShowSortOptions(true)}
              secondary
            >
              Sort
            </controls.SmallButton>
            <controls.SmallButton
              onClick={() => setShowColumnSelection(true)}
              secondary
            >
              Configure Columns
            </controls.SmallButton>
            <div>-</div>
            <controls.SmallButton
              onClick={downloadOutputCSV}
              disabled={!hasOutput}
            >
              Download CSV
            </controls.SmallButton>
            <CopyButton onClick={copyOutputCSV} disabled={!hasOutput}>
              Copy CSV
            </CopyButton>
          </controls.ControlGroup>
        )}

        {outputMode === 'text' && (
          <controls.ControlGroup>
            <controls.SmallButton
              onClick={() => setShowSortOptions(true)}
              secondary
            >
              Sort
            </controls.SmallButton>
            <controls.SmallButton
              onClick={() => setShowTextConfiguration(true)}
              secondary
            >
              Configure Text
            </controls.SmallButton>
            <div>-</div>
            <controls.SmallButton
              onClick={downloadOutputText}
              disabled={!hasOutput}
            >
              Download Text
            </controls.SmallButton>
            <CopyButton onClick={copyOutputText} disabled={!hasOutput}>
              Copy Text
            </CopyButton>
          </controls.ControlGroup>
        )}

        {outputMode === 'yaml' && (
          <controls.ControlGroup>
            <controls.SmallButton
              onClick={() => setShowSortOptions(true)}
              secondary
            >
              Sort
            </controls.SmallButton>
            <controls.SmallButton
              onClick={() => setShowColumnSelection(true)}
              secondary
            >
              Configure Attributes
            </controls.SmallButton>
            <div>-</div>
            <controls.SmallButton
              onClick={downloadOutputYAML}
              disabled={!hasOutput}
            >
              Download YAML
            </controls.SmallButton>
            <CopyButton onClick={copyOutputYAML} disabled={!hasOutput}>
              Copy YAML
            </CopyButton>
          </controls.ControlGroup>
        )}

        {outputMode === 'images' && (
          <controls.ControlGroup>
            <controls.SmallButton
              onClick={() => setShowSortOptions(true)}
              secondary
            >
              Sort
            </controls.SmallButton>
            <div>-</div>
            <controls.SmallButton
              onClick={() => setShowArchiveDownloader(true)}
              disabled={!hasOutput}
            >
              Download Images...
            </controls.SmallButton>
            <controls.SmallButton
              onClick={() => setShowCompositeDownloader(true)}
              disabled={!hasOutput}
            >
              Download Composite...
            </controls.SmallButton>
          </controls.ControlGroup>
        )}
      </div>

      <div className={styles.outputView}>
        {sortedData.length === 0 ? (
          <div className={styles.empty}>
            Enter a list of cards, csv, or search query and load cards to see
            results.
          </div>
        ) : outputAttributes.length === 0 ? (
          <div className={styles.empty}>
            Configure output attributes to view cards
          </div>
        ) : (
          <>
            {outputMode === 'table' && (
              <CardsTable
                rows={sortedData}
                outputAttributes={outputAttributes}
                sortMethod={sortMethod}
                setSortMethod={setSortMethod}
              />
            )}
            {outputMode === 'text' && (
              <TextOutput rows={sortedData} rowTemplate={textRowTemplate} />
            )}
            {outputMode === 'csv' && (
              <CSVPreview
                data={sortedData}
                outputAttributes={outputAttributes}
                includeHeadings={includeHeadings}
              />
            )}
            {outputMode === 'images' && <Images rows={sortedData} />}
            {outputMode === 'yaml' && (
              <YAMLPreview
                data={sortedData}
                outputAttributes={outputAttributes}
              />
            )}
          </>
        )}
      </div>

      <Modal
        title="Configure attributes to display and export"
        description="Select the attributes to output for each card. Labels are used in CSV headings and similar. Add custom attributes for additional columns, empty or pre-filled with a single value."
        presented={showColumnSelection}
        dismiss={() => setShowColumnSelection(false)}
      >
        <AttributeSelection
          outputMode={outputMode}
          customAttributes={customAttributes}
          outputAttributes={outputAttributes}
          setOutputAttributes={setOutputAttributes}
          includeHeadings={includeHeadings}
          setIncludeHeadings={setIncludeHeadings}
          dismiss={() => setShowColumnSelection(false)}
        />
      </Modal>

      <Modal
        title="Configure formatting for exported text"
        description="Configure a text list of cards including any card attributes. Choose from a preset or create any custom list format to suit your needs. Wrap card attributes in curly braces, along with any other text you want to appear on each row."
        presented={showTextConfiguration}
        dismiss={() => setShowTextConfiguration(false)}
      >
        <TextConfiguration
          rowTemplate={textRowTemplate}
          setRowTemplate={setTextRowTemplate}
          dismiss={() => setShowTextConfiguration(false)}
        />
      </Modal>

      <Modal
        title="Sort Options"
        presented={showSortOptions}
        dismiss={() => setShowSortOptions(false)}
      >
        <SortOptions
          sortMethod={sortMethod}
          setSortMethod={setSortMethod}
          dismiss={() => setShowSortOptions(false)}
        />
      </Modal>

      {shortArchiveDownloader && (
        <ImageArchiveDownloadModal
          rows={sortedData}
          dismiss={() => setShowArchiveDownloader(false)}
        />
      )}

      {showCompositeDownloader && (
        <ImageCompositeDownloaderModal
          rows={sortedData}
          dismiss={() => setShowCompositeDownloader(false)}
        />
      )}
    </div>
  )
}
