import * as styles from './Help.module.scss'

import React from 'react'
import { Link } from 'gatsby'

export const Help: React.FC = () => {
  return (
    <div className={styles.container}>
      <h2 className={styles.heading}>MTG List Formatter by Lucky Paper</h2>

      <p>A swiss-army-knife for Magic card data.</p>

      <p>
        This tool works with lists of MTG cards, automating tasks like
        transforming card lists between formats used by different systems,
        adding card details to spreadsheets, or fetching card images.
      </p>

      <p>
        <strong>Input</strong> a list of cards or fetch a list via a
        <a href="https://scryfall.com">Scryfall</a> search or{' '}
        <a href="https://cubecobra.com">Cube Cobra</a> link. Many common formats
        are supported.
      </p>

      <p>
        <strong>Output</strong> a highly configurable data set with card details
        fetched from <a href="https://scryfall.com">Scryfall</a>. Formats
        include CSV, images, data formats, or configurable text.
      </p>

      <p>
        If you have any issues, or a use case this tool doesn&rsquo;t quite
        cover, don&rsquo;t hesitate to reaach out to me on twitter{' '}
        <a href="https://twitter.com/ahmattox" target="_blank" rel="noreferrer">
          @ahmattox
        </a>
        .
      </p>

      <hr className={styles.rule} />

      <h2 className={styles.heading}>
        Questions, issues, or feature requests?
      </h2>

      <p>
        Let me know on twitter{' '}
        <a href="https://twitter.com/ahmattox" target="_blank" rel="noreferrer">
          @ahmattox
        </a>
        , or <Link to="/contact/">contact Lucky Paper</Link> by email.
      </p>
    </div>
  )
}
