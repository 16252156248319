import { clamp } from 'lodash'

import { RowWithData } from '../../data/types'

import { imageURLsForRows } from './imageURLsForRows'
import {
  ImageGridDownloadOptions,
  cardAspectRatio,
  maxImageDimension,
  maxImages,
} from './configuration'
import { CompositeCardImageLayout } from './CardLayout'
import { notNan } from 'src/utils/typeConversions'

export function generateGridLayout(
  rows: RowWithData[],
  options: ImageGridDownloadOptions,
): CompositeCardImageLayout {
  const { columns, backgroundColor } = options

  const errors = []

  const imageWidth = clamp(options.imageWidth, 16, maxImageDimension)
  const padding = clamp(notNan(options.padding) ?? 0, 0, options.imageWidth / 2)
  const gutter = clamp(
    notNan(options.gutter) ?? 0,
    0,
    (options.imageWidth - padding * 2) / (columns - 1),
  )

  const allImageURLs = imageURLsForRows(rows, options.dfcBacks)
  const imageURLs = allImageURLs.slice(0, maxImages)

  if (allImageURLs.length > imageURLs.length) {
    errors.push(`A maximum of ${maxImages} will be rendered.`)
  }

  const columnWidth =
    (imageWidth - padding * 2 - gutter * (columns - 1)) / columns
  const rowHeight = columnWidth * cardAspectRatio
  const rowCount = Math.ceil(imageURLs.length / columns)
  let imageHeight = rowCount * rowHeight + padding * 2 + gutter * (rowCount - 1)

  imageHeight = Math.ceil(imageHeight)

  if (imageHeight > maxImageDimension) {
    errors.push('Image is too large and will be clipped.')
    imageHeight = maxImageDimension
  }

  const cards = imageURLs.map((imageURL, index) => {
    const column = index % columns
    const row = Math.floor(index / columns)

    const x = column * (columnWidth + gutter) + padding
    const y = row * (rowHeight + gutter) + padding

    return {
      url: imageURL,
      x,
      y,
      width: columnWidth,
      height: rowHeight,
    }
  })

  return {
    imageSize: {
      width: imageWidth,
      height: imageHeight,
    },
    cardSize: {
      width: columnWidth,
      height: rowHeight,
    },
    backgroundColor,
    cards,
    errors,
  }
}
