import * as styles from './ListFormatter.module.scss'

import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { v4 as uuid } from 'uuid'
import classNames from 'classnames'
import { Link } from 'gatsby'
import { difference, uniq } from 'lodash'

import { usePersistentState } from 'utils/usePersistentState'
import { AccentColor } from 'utils/accent-color/AccentColorContext'

import {
  RowWithData,
  InputMode,
  Attribute,
  OutputAttribute,
} from './data/types'

import { parseInput } from './data/parseInput'
import { IDTransformer, inferIDTransformer } from './data/inferIDTransformer'
import { useNormalizeSetID } from './data/useNormalizeSetID'
import { standardOutputConfiguration } from './data/outputConfigurationPresets'
import { cardAttributeNames } from './data/cardAttributes'
import { loadFlattenedCards } from './data/loadCards'

import { MetaControls } from './MetaControls'
import { Footer } from './Footer'
import { Input } from './input/Input'
import { Output } from './output/Output'

export const ListFormatter: React.FC = () => {
  const [stackedLayout, setStackedLayout] = usePersistentState(
    'list-formatter-stacked-layout',
    false,
  )

  // Mode

  const [inputMode, setInputMode] = usePersistentState<InputMode>(
    'list-formatter-input-mode',
    'list',
  )

  // Sets

  const normalizeSetID = useNormalizeSetID()

  // Input Options

  const [listInput, setListInput] = usePersistentState(
    'list-formatter-input',
    '',
  )

  const [searchQuery, setSearchQuery] = usePersistentState(
    'list-formatter-query',
    '',
  )

  const [linkInput, setLinkInput] = usePersistentState(
    'list-formatter-link-input',
    '',
  )

  const [globalQueryExtension, setGlobalQueryExtension] = usePersistentState(
    'list-formatter-global-query',
    '',
  )

  const [listInputMode, listInputRows, listInputAttributes] = useMemo(() => {
    return parseInput(listInput)
  }, [listInput])

  const [overrideIDTransformer, setOverrideIDTransformer] = usePersistentState(
    'list-formatter-infer-columns',
    false,
  )

  const [idTransformer, setIDTransformer] = usePersistentState<
    Partial<IDTransformer>
  >('list-formatter-override-transformer', {})

  useEffect(() => {
    if (!overrideIDTransformer) {
      // TODO: Improve error handling if input structure can't be inferred.
      const idTransformer = inferIDTransformer(listInputAttributes)

      if (idTransformer != null) {
        setIDTransformer(idTransformer)
      } else {
        setIDTransformer({})
      }
    }
  }, [listInputAttributes, overrideIDTransformer, setIDTransformer])

  // Output Options

  const [outputAttributes, setOutputAttributes] = usePersistentState<
    OutputAttribute[]
  >('list-formatter-output-attributes', standardOutputConfiguration)

  // Loading & Processing Data

  const [fetchedData, setFetchedData] = useState<RowWithData[]>([])

  const [customAttributes, setCustomAttributes] = useState<Attribute[]>([])
  const previousCustomAttributes = useRef<string[]>([])
  useEffect(() => {
    // Enable any newly added custom columns by default
    const attributeNames = customAttributes
      .map((attribute) => attribute.name)
      .filter((name) => !cardAttributeNames.includes(name))
    const newAttributeNames = difference(
      attributeNames,
      previousCustomAttributes.current,
    )

    if (newAttributeNames.length > 0) {
      setOutputAttributes([
        ...newAttributeNames.map((key) => ({
          key,
          id: uuid(),
          source: 'input' as const,
        })),
        ...outputAttributes,
      ])
    }

    previousCustomAttributes.current = uniq([
      ...previousCustomAttributes.current,
      ...attributeNames,
    ])
  }, [customAttributes, outputAttributes, setOutputAttributes])

  const [loading, setLoading] = useState(false)

  const load = useCallback(() => {
    setLoading(true)

    // Wrap the query in parentheses so the global query attributes apply to
    // all statements if the query is "A" or "B".
    loadFlattenedCards(
      inputMode,
      [globalQueryExtension, `(${searchQuery})`].join(' '),
      linkInput,
      listInputRows,
      idTransformer,
      normalizeSetID,
    ).then((result) => {
      setLoading(false)
      setCustomAttributes(inputMode === 'list' ? listInputAttributes : [])
      if (result != null) {
        setFetchedData(result)
      }
    })
  }, [
    globalQueryExtension,
    idTransformer,
    inputMode,
    linkInput,
    listInputAttributes,
    listInputRows,
    normalizeSetID,
    searchQuery,
  ])

  // Reset

  const reset = useCallback(() => {
    setListInput('')
    setSearchQuery('')
    setFetchedData([])
    setCustomAttributes([])
    setOutputAttributes(standardOutputConfiguration)
  }, [setListInput, setOutputAttributes, setSearchQuery])

  // Render

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <h1 className={styles.title}>
          MTG List Formatter by <Link to="/">Lucky Paper</Link>
        </h1>
        <div className={styles.metaControls}>
          <MetaControls
            reset={reset}
            stackedLayout={stackedLayout}
            setStackedLayout={setStackedLayout}
          />
        </div>
      </div>

      <div
        className={classNames(styles.mainContent, {
          [styles.columns]: !stackedLayout,
        })}
      >
        <Input
          inputMode={inputMode}
          setInputMode={setInputMode}
          listInput={listInput}
          setListInput={setListInput}
          listInputMode={listInputMode}
          listInputAttributes={listInputAttributes}
          searchQuery={searchQuery}
          setSearchQuery={setSearchQuery}
          linkInput={linkInput}
          setLinkInput={setLinkInput}
          loading={loading}
          load={load}
          idTransformer={idTransformer}
          setIDTransformer={setIDTransformer}
          overrideIDTransformer={overrideIDTransformer}
          setOverrideIDTransformer={setOverrideIDTransformer}
          globalQueryExtension={globalQueryExtension}
          setGlobalQueryExtension={setGlobalQueryExtension}
        />

        <AccentColor color="#BD349C">
          <Output
            customAttributes={customAttributes}
            fetchedData={fetchedData}
            outputAttributes={outputAttributes}
            setOutputAttributes={setOutputAttributes}
          />
        </AccentColor>
      </div>

      <Footer />
    </div>
  )
}
