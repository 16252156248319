// title: Magic the Gathering Card List Formatter

import React from 'react'

import { AccentColor } from 'utils/accent-color/AccentColorContext'

import { BaseLayout } from 'src/components/layout/Base'
import PageMetaData from 'components/layout/PageMetaData'

import { ListFormatter } from 'components/list-formatter/ListFormatter'
import ErrorBoundary from 'components/list-formatter/ErrorBoundary'

interface Props {
  pageContext: {
    url: string
  }
}

const ListFormatterPage: React.FC = () => {
  return (
    <BaseLayout>
      <AccentColor color="#8734BD">
        <ErrorBoundary>
          <ListFormatter />
        </ErrorBoundary>
      </AccentColor>
    </BaseLayout>
  )
}

export const Head: React.FC<Props> = (props) => {
  const { pageContext } = props

  return (
    <>
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1, maximum-scale=1"
      />
      <PageMetaData
        metaData={{
          title: 'Magic the Gathering Card List Formatter',
          description:
            'Transform and enrich lists of Magic: the Gathering cards. Fetch card data from Scryfall for a set of cards, transform lists between common formats, and fetch card images.',
          themeColor: '#ffffff',
          ogImageURL: 'https://luckypaper.co/og-images/list-formatter.jpg',
        }}
        url={pageContext.url}
        schema={{
          '@context': 'https://schema.org',
          '@type': 'WebPage',
          name: 'Magic the Gathering Card List Formatter',
          url: props.pageContext.url,
        }}
      />
    </>
  )
}

export default ListFormatterPage
