import { clamp, range } from 'lodash'

import { RowWithData } from '../../data/types'

import {
  ImageGridDownloadOptions,
  cardAspectRatio,
  maxImageDimension,
  maxImages,
} from './configuration'
import { CompositeCardImageLayout } from './CardLayout'
import { notNan } from 'src/utils/typeConversions'

export function generateColumnLayout(
  rows: RowWithData[],
  options: ImageGridDownloadOptions,
): CompositeCardImageLayout {
  const { columns, skipEmptyColumns, backgroundColor } = options

  const errors = []

  if (rows.length > maxImages) {
    errors.push(`A maximum of ${maxImages} will be rendered.`)
  }

  const maxCMC = rows.reduce(
    (result, row) => Math.max(result, row.card?.cmc ?? 0),
    0,
  )

  let curveColumns = rows.slice(0, maxImages).reduce((result, row) => {
    const imageURL =
      row.card?.image_uris?.normal ??
      row.card?.card_faces?.[0]?.image_uris.normal

    if (imageURL) {
      const column = row.card?.cmc ?? 0

      result[column].push(imageURL)
    }

    return result
  }, range(0, maxCMC + 1).map(() => []) as string[][])

  if (skipEmptyColumns) {
    curveColumns = curveColumns.filter((column) => column.length > 0)
  }

  if (curveColumns.length > columns) {
    curveColumns = [
      ...curveColumns.slice(0, columns - 1),
      curveColumns.slice(columns - 1).flatMap((c) => c),
    ]
  }

  const imageWidth = clamp(options.imageWidth, 16, maxImageDimension)
  const padding = clamp(notNan(options.padding) ?? 0, 0, options.imageWidth / 2)
  const gutter = clamp(
    notNan(options.gutter) ?? 0,
    0,
    (options.imageWidth - padding * 2) / (columns - 1),
  )

  const columnWidth =
    (imageWidth - padding * 2 - gutter * (columns - 1)) / columns
  const cardHeight = columnWidth * cardAspectRatio
  const rowHeight = columnWidth * 0.2
  const rowCount = curveColumns.reduce((result, col) => {
    return Math.max(result, col.length)
  }, 0)
  let imageHeight = (rowCount - 1) * rowHeight + padding * 2 + cardHeight

  imageHeight = Math.ceil(imageHeight)

  if (imageHeight > maxImageDimension) {
    errors.push('Image is too large and will be clipped.')
    imageHeight = maxImageDimension
  }

  const cards = []

  for (const [columnIndex, column] of curveColumns.entries()) {
    for (const [rowIndex, imageURL] of column.entries()) {
      const x = columnIndex * (columnWidth + gutter) + padding
      const y = rowIndex * rowHeight + padding

      cards.push({
        url: imageURL,
        x,
        y,
        width: columnWidth,
        height: cardHeight,
      })
    }
  }

  return {
    imageSize: {
      width: imageWidth,
      height: imageHeight,
    },
    cardSize: {
      width: columnWidth,
      height: imageHeight,
    },
    backgroundColor,
    cards,
    errors,
  }
}
