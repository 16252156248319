import * as styles from './CopyButton.module.scss'

import React, { useState, useCallback } from 'react'
import classNames from 'classnames'

import { SmallButton } from 'components/controls'

import Check from '../icons/Check'

interface Props {
  children: string
  disabled?: boolean
  onClick(event: React.MouseEvent<HTMLButtonElement>): void
}

export const CopyButton: React.FC<Props> = (props) => {
  const { children, disabled, onClick } = props

  const [copied, setCopied] = useState(false)

  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      onClick(event)

      setCopied(true)

      const timer = setTimeout(() => {
        setCopied(false)
      }, 1200)

      return () => {
        clearInterval(timer)
      }
    },
    [onClick],
  )

  return (
    <div className={styles.container}>
      <SmallButton onClick={handleClick} disabled={disabled}>
        {children}
      </SmallButton>

      <div
        className={classNames(styles.copiedNotification, {
          [styles.visible]: copied,
        })}
      >
        <div>Copied to Clipboard</div>
        <div>
          <Check color="#ffffff" />
        </div>
      </div>
    </div>
  )
}
