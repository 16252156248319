import {
  CanvasRenderingContext,
  drawRoundedRect,
} from 'src/utils/canvas-rendering/canvasDrawing'

import { renderCardImage } from './renderCardImage'
import { CompositeCardImageLayout } from './CardLayout'

export async function renderCardSetImage(
  context: CanvasRenderingContext,
  layout: CompositeCardImageLayout,
  preview: boolean,
) {
  context.fillStyle = layout.backgroundColor
  context.fillRect(0, 0, layout.imageSize.width, layout.imageSize.height)

  for (const card of layout.cards) {
    if (preview) {
      drawRoundedRect(
        context,
        card.x,
        card.y,
        card.width,
        card.height,
        card.height * 0.04,
      )
      context.fillStyle = '#BE359C'
      context.fill()
      context.strokeStyle = layout.backgroundColor
      context.lineWidth = 4
      context.stroke()
    } else {
      await renderCardImage(
        context,
        card.url,
        card.x,
        card.y,
        card.width,
        card.height,
      )
    }
  }
}
