import { compact } from 'lodash'

import { RowWithData } from '../../data/types'

export function imageURLsForRows(
  rows: RowWithData[],
  includeDFCBacks: boolean,
) {
  return rows.flatMap((row) => {
    if (row.card == null) {
      return []
    }

    if (row.card.card_faces != null) {
      if (includeDFCBacks) {
        return compact(
          row.card.card_faces.map((face) => face.image_uris?.normal),
        )
      }

      return row.card.card_faces[0]?.image_uris?.normal ?? []
    }

    return row.card.image_uris.normal
  })
}
